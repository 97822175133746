import { FC } from "react";
import { Typography } from "@material-ui/core"

import useStyles from "./Footer.style";


const Footer: FC<{}> = () => {
    const classes = useStyles()
    return (<>
        <div className={classes.footer}>
            <Typography variant="subtitle1" align="center" color="textSecondary">
                rarejava.com &copy; {new Date().getFullYear()}
            </Typography>
        </div>
    </>)
}

export default Footer