import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Switch, Route, BrowserRouter as Router, RouteComponentProps } from "react-router-dom";
import { routes } from "./config/routes";
import RouteItem from "./model/RouteItem.model";
import Layout from "./components/layout/Layout";
import { homeTheme } from "./res/values/themes";
import { responsiveFontSizes, ThemeProvider, } from "@material-ui/core/styles";
import { useState } from "react";

const App = () => {
    const [theme, setTheme] = useState(homeTheme);
    const currentTheme = responsiveFontSizes(theme);
    return (
        <>
            <CssBaseline />
            <Router>
                <ThemeProvider theme={currentTheme}>
                    <Switch>
                        <Layout>
                            {routes.map((route: RouteItem, index) => (
                                <Route
                                    key={`${route.key}`}
                                    path={`${route.path}`}
                                    exact
                                    render={(props: RouteComponentProps) => (
                                        <route.component
                                            setTheme={setTheme}
                                            {...props} />
                                    )}
                                />
                            ))}
                        </Layout>
                    </Switch>
                </ThemeProvider>
            </Router>
        </>
    )
}

export default App;