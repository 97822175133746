import { FC } from "react";
import { Typography, AppBar, Toolbar, SvgIcon } from "@material-ui/core"
import useStyles from "./Header.style";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "../../config/routes";
import RouteItem from "../../model/RouteItem.model";


const Header: FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
    const classes = useStyles()
    const currentPage: RouteItem = routes.reduce((previous, current) =>
        current.path === location.pathname ? current : previous);
    return (<>
        <AppBar position="relative">
            <Toolbar>
                <>
                    <Link to="/">
                        <SvgIcon component={currentPage.displayIcon} className={classes.icon} />
                    </Link>
                    <Typography variant="h6" color="textPrimary">
                        {currentPage.displayName}
                    </Typography>
                </>
            </Toolbar>
        </AppBar>
    </>)
}

export default withRouter(Header)