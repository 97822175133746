import { Avatar, Container, Grid, Typography } from "@material-ui/core";
import React, { FC, ReactElement, useEffect } from "react";
import IPage from "../model/Page.model";
import { miBandFinderTheme } from "../res/values/themes";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useStyles from "./MiBandFinder.style";

const MiBandFinder: FC<IPage> = ({ setTheme }): ReactElement => {
    useEffect(() => {
        setTheme(miBandFinderTheme)
    }, [setTheme]);

    const classes = useStyles()
    return (
        <>
            <Helmet>
                <title>
                    Mi Band Finder
                </title>
            </Helmet>
            <div className={classes.container}>
                <Container maxWidth="sm" >
                    <div>
                        <Grid container>
                        <Grid item style={{ display: "flex" }}>
                                <Avatar alt="Avatar" src="assets/mbf-logo.png" />
                                <Typography style={{ display: "flex",justifyContent: "center", flexDirection: "column"}} variant="h4">&nbsp;Mi Band Finder</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5"><br/>Lost your Mi Band? </Typography>
                                <Typography variant="body1" gutterBottom>Mi Band Finder will help you find it!
                                    Scan for any nearby Mi Band or provide your Mi Band MAC address to find it straight away.</Typography>
                            </Grid>
                            <Grid item xs={10} sm={8} md={6} >
                                <a href='https://play.google.com/store/apps/details?id=com.rarejava.mibandfinder'>
                                    <img width="100%" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                </a>
                            </Grid>


                        </Grid>
                    </div>
                </Container>
            </div>
            <div className={classes.content}>
                <Container maxWidth="sm" >
                    <Link to="/mi-band-finder/privacy">Privacy policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/mi-band-finder/terms">Terms of service</Link>
                </Container>
            </div>
            {/* <div className={classes.content}>
                <Container maxWidth="sm" >
                    <Typography variant="h4">How can I find it? </Typography>
                    <Typography variant="body1" gutterBottom>You only need to specify which Mi Band you want to find. This can be done in 2 different ways:</Typography>

                    <Typography variant="h6" gutterBottom>Type in the Bluetooth MAC address:</Typography>
                    <Typography variant="body1" gutterBottom>You can find the MAC address of your Mi Band inside the Mi Fit application. Open your Mi Fit app and click on the Profile tab, under the My devices section click the Mi Band row. There you will be able to find the MAC address at the very bottom of the screen.</Typography>

                    <Typography variant="h6" gutterBottom>Or scan nearby devices:</Typography>
                    <Typography variant="body1" gutterBottom>Alternatively, you can start the Scanner and wait for your Mi Band to be listed. Once you see it in the list just click on it and you are ready to go.</Typography>

                    <Typography variant="body1">Finally, click the "Find Mi Band" button and start moving slowly towards the area where the signal strength increases. The higher the signal the closer is your Mi Band.</Typography>
                </Container>
            </div> */}
        </>
    );
};

export default MiBandFinder