import { FC, ReactNode } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";

interface Props {
    children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
    return (<>
        <Header/>
        <main>
            {children}
        </main>
        <footer>
            <Footer />
        </footer>
    </>)
}

export default Layout