// APP TEXT
export const APP_TITLE = "Welcome, Developer"
export const FOOTER_TEXT = `${new Date().getFullYear()}`

// HEADER
export const HEADER_TITLE = "rareJava"


// PAGES TITLE
export const PAGE_HOME_TITLE = "Hello!"
export const PAGE_HOME_DESCRIPTION = "I'm a wannabe Android developer. Here you will find my beloved creations."

export const PAGE_TITLE_PROJECT_01 = "Project 1"
