import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4, 0, 4)
    },
    cardGrid: {
        padding: "32px 0",
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        margin: theme.spacing(0, 1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow:theme.shadows[4]
    },
    cardMedia: {
        paddingTop: "56.25%"

    },
    cardContent: {
        flexGrow: 1

    },
    avatar:{
        height: "100%",
        width: "100%",
    },
    link:{
        textDecoration: "none"
    },
    welcome:{
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(4,2,0,2)
        
    },
    content:{
        backgroundColor: theme.palette.background.paper,
    }
}))

export default useStyles