import { Avatar, Container, Grid, Typography } from "@material-ui/core";
import React, { FC, ReactElement, useEffect } from "react";
import IPage from "../model/Page.model";
import { miWalkingPadTheme } from "../res/values/themes";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import useStyles from "./MiWalkingPad.style";

const MiWalkingPad: FC<IPage> = ({ setTheme }): ReactElement => {
    useEffect(() => {
        setTheme(miWalkingPadTheme)
    }, [setTheme]);

    const classes = useStyles()
    return (
        <>
            <Helmet>
                <title>
                    Mi WalkingPad
                </title>
            </Helmet>
            <div className={classes.container}>
                <Container maxWidth="sm" >
                    <div>
                        <Grid container>
                            <Grid item style={{ display: "flex" }}>
                                <Avatar alt="Avatar" src="assets/mwp-logo.png" />
                                <Typography style={{ display: "flex",justifyContent: "center", flexDirection: "column"}} variant="h4">&nbsp;Mi WalkingPad</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5"><br/>Control your WalkingPad</Typography>
                                <Typography variant="body1" gutterBottom>Control your WalkingPad with this app and sync your walks with Google Fit.</Typography>
                            </Grid>
                            <Grid item xs={10} sm={8} md={6} >
                                <a href='https://play.google.com/store/apps/details?id=com.rarejava.miwalkingpad'>
                                    <img width="100%" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className={classes.content}>
                <Container maxWidth="sm" >
                    <Link to="/mi-walking-pad/privacy">Privacy policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/mi-walking-pad/terms">Terms of service</Link>
                </Container>
            </div>
        </>
    );
};

export default MiWalkingPad