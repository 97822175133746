

import RouteItem from '../model/RouteItem.model';
import MiBandFinder from '../pages/MiBandFinder';
import MiWalkingPad from '../pages/MiWalkingPad';
import Home from '../pages/Home'
import { HomeRounded, KeyboardArrowLeft } from '@material-ui/icons';
import MiBandFinderPrivacyPolicy from '../pages/MiBandFinderPrivacyPolicy';
import MiBandFinderTermsConditions from '../pages/MiBandFinderTermsConditions';
import MiWalkingPadPrivacyPolicy from '../pages/MiWalkingPadPrivacyPolicy';
import MiWalkingPadTermsConditions from '../pages/MiWalkingPadTermsConditions';
import MotionBlurCamera from '../pages/MotionBlurCamera';
import MotionBlurCameraPrivacyPolicy from '../pages/MotionBlurCameraPrivacyPolicy';
import MotionBlurCameraTermsConditions from '../pages/MotionBlurCameraTermsConditions';

export const routes: Array<RouteItem> = [
    {
        key: "home",
        path: "/",
        component: Home,
        displayName: "Home",
        displayIcon: HomeRounded
        // theme: homeTheme

    },
    {
        key: "mbf",
        path: "/mi-band-finder",
        component: MiBandFinder,
        displayName: "Mi Band Finder",
        displayIcon: KeyboardArrowLeft
        // theme: miBandFinder

    },
    {
        key: "mbf-privacy",
        path: "/mi-band-finder/privacy",
        component: MiBandFinderPrivacyPolicy,
        displayName: "Mi Band Finder - Privacy Policy",
        displayIcon: KeyboardArrowLeft
        // theme: miBandFinder

    },
    {
        key: "mbf-terms",
        path: "/mi-band-finder/terms",
        component: MiBandFinderTermsConditions,
        displayName: "Mi Band Finder - Terms & Conditions",
        displayIcon: KeyboardArrowLeft
        // theme: miBandFinder

    }
    ,
    {
        key: "mwp",
        path: "/mi-walking-pad",
        component: MiWalkingPad,
        displayName: "Mi WalkingPad",
        displayIcon: KeyboardArrowLeft
    },
    {
        key: "mwp-privacy",
        path: "/mi-walking-pad/privacy",
        component: MiWalkingPadPrivacyPolicy,
        displayName: "Mi WalkingPad - Privacy Policy",
        displayIcon: KeyboardArrowLeft
    },
    {
        key: "mwp-terms",
        path: "/mi-walking-pad/terms",
        component: MiWalkingPadTermsConditions,
        displayName: "Mi WalkingPad - Terms & Conditions",
        displayIcon: KeyboardArrowLeft
    },
    {
        key: "mbc",
        path: "/motion-blur-camera",
        component: MotionBlurCamera,
        displayName: "Motion Blur Camera",
        displayIcon: KeyboardArrowLeft
    },
    {
        key: "mbc-privacy",
        path: "/motion-blur-camera/privacy",
        component: MotionBlurCameraPrivacyPolicy,
        displayName: "Motion Blur Camera - Privacy Policy",
        displayIcon: KeyboardArrowLeft
    },
    {
        key: "mbc-terms",
        path: "/motion-blur-camera/terms",
        component: MotionBlurCameraTermsConditions,
        displayName: "Motion Blur Camera - Terms & Conditions",
        displayIcon: KeyboardArrowLeft
    }
    
]