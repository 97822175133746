import { createTheme, Theme } from '@material-ui/core/styles';

// define light theme colors
export const homeTheme: Theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#e0e0e0",
        }
    },
});

export const miBandFinderTheme: Theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#FF6000",
        },
        text: {
            primary: "#FFFFFF"
        }
    },
});

export const miWalkingPadTheme: Theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#00c853",
        },
        text: {
            primary: "#FFFFFF"
        }
    },
});

export const motionBlurCameraTheme: Theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#2962ff",
        },
        text: {
            primary: "#FFFFFF"
        }
    },
});
// color: theme.palette.text.primary
// { "palette": { 
//     "common": { "black": "#000", "white": "#fff" }, 
//     "background": { "paper": "#fff", "default": "#fafafa" }, 
//     "primary": { "light": "#7986cb", "main": "rgba(61, 220, 132, 1)", "dark": "#303f9f", "contrastText": "#fff" },
//      "secondary": { "light": "#ff4081", "main": "#f50057", "dark": "#c51162", "contrastText": "#fff" }, 
//      "error": { "light": "#e57373", "main": "#f44336", "dark": "#d32f2f", "contrastText": "#fff" }, 
//      "text": { "primary": "rgba(0, 0, 0, 0.87)", "secondary": "rgba(0, 0, 0, 0.54)", "disabled": "rgba(0, 0, 0, 0.38)", "hint": "rgba(0, 0, 0, 0.38)" } } }
