import React, { FC, ReactElement, useEffect } from "react";
import { Typography, Button, Card, CardActions, CardContent, CardMedia, Grid, Container, Avatar, CardHeader } from "@material-ui/core"

import useStyles from "./Home.style";
import { PAGE_HOME_TITLE, PAGE_HOME_DESCRIPTION } from "../res/values/strings"
import { Link } from "react-router-dom";
import IPage from "../model/Page.model";
import { homeTheme } from "../res/values/themes";
import { Helmet } from "react-helmet";



const Home: FC<IPage> = ({ setTheme }): ReactElement => {
    useEffect(() => {
        setTheme(homeTheme)
    }, [setTheme]);
    const classes = useStyles()
    return (
        <>
            <Helmet>
                <title>
                    Home
                </title>
            </Helmet>

            <div className={classes.container}>
                <Container maxWidth="md">
                    <div>
                        <Grid container justifyContent="center">
                            <Grid item xs={8} sm={6} md={4}>
                                <Avatar alt="Avatar" src="assets/avatar.png" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={8} sm={6} md={4} className={classes.welcome}>
                                <Typography variant="h3" align="center" color="textPrimary" gutterBottom>{PAGE_HOME_TITLE}</Typography>
                                <Typography variant="h5" align="center" color="textSecondary" paragraph>{PAGE_HOME_DESCRIPTION}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className={classes.content}>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<Avatar aria-label="Mi Band Finder" src="assets/mbf-logo.png" />}
                                    title="Mi Band Finder"
                                    subheader="Recover your lost Mi Band"
                                />
                                <CardMedia className={classes.cardMedia}
                                    image="assets/mibandfinder.png"
                                    title="Mi Band Finder"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography>
                                        Lost your Mi Band? Mi Band Finder will help you find it! Scan for any nearby Mi Band or provide your
                                        Mi Band MAC address to find it straight away.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link to="/mi-band-finder" className={classes.link}>
                                        <Button size="small" >Learn more</Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<Avatar aria-label="Mi WalkingPad" src="assets/mwp-logo.png" />}
                                    title="Mi Walking Pad"
                                    subheader="Control your WalkingPad"
                                />
                                <CardMedia className={classes.cardMedia}
                                    image="assets/miwalkingpad.png"
                                    title="Mi WalkingPad"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography>
                                        Control your WalkingPad with this app and sync your walks with Health Connect or Google Fit.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link to="/mi-walking-pad" className={classes.link}>
                                        <Button size="small" >Learn more</Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<Avatar aria-label="Motion Blur Camera" src="assets/mbc-logo.png" />}
                                    title="Motion Blur Camera"
                                    subheader=""
                                />
                                <CardMedia className={classes.cardMedia}
                                    image="assets/motionblurcamera.png"
                                    title="Motion Blur Camera"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography>
                                        Slow shutter camera to create long exposure motion blur effect pictures.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link to="/motion-blur-camera" className={classes.link}>
                                        <Button size="small" >Learn more</Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default Home